import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        tags={post.frontmatter.tags || []}
      />
      <article className="content">
        <div className="container">   
          <header className='hero is-fullheight-with-navbar'>
            <div className='hero-body'>
              <div className='container has-text-centered'>
                <h1 className="title is-1">
                  {post.frontmatter.title}
                </h1>
                <p
                  style={{
                    //display: `block`,
                  }}
                >
                  {post.frontmatter.date}
                </p>   
              </div>
            </div> 
          </header>
        </div>

        <main className="section">
          <div className="container" dangerouslySetInnerHTML={{ __html: post.html }} />
        </main>

        <hr
          style={{
          }}
        />

        <footer className='section'>
          <div className="container">   
            <nav>
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </nav> 
          </div>
        </footer>
      </article>

        
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
